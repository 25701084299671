import { DeepPartial } from "@apollo/client/utilities";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  OrderItem,
  ProductModifierOption,
  ProductModifierSpecialSubtype,
  SourceProduct,
  SourceType,
} from "../../../../generated/requests/pos";
import { ImagesV2 } from "../../../../public/images/all";
import { formatMoney } from "../../../../static/lib/util";
import Text from "../../atoms/Text/Text";
import { ClientOrderItem } from "../../contexts/OrderContext/helpers";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { getUniqueItemId } from "../../contexts/OrderContextNew/helpers";
import Button from "../../molecules/Button/Button";
import Modal from "../../molecules/Modal/Modal";
import { useGetSource, useGetStoreBySlug } from "../../operations/queries";

interface AddGiftWrapModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedProduct: DeepPartial<SourceProduct>;
  onAddToBag?: (selectedGiftWrapModifier: ProductModifierOption) => void;
}

export function AddGiftWrapModal({ isOpen, onClose, selectedProduct, onAddToBag }: AddGiftWrapModalProps) {
  return <GiftWrapModal isOpen={isOpen} onClose={onClose} selectedProduct={selectedProduct} onAddToBag={onAddToBag} />;
}

interface UpdateGiftWrapModalProps {
  isOpen: boolean;
  onClose?: () => void;
  itemToUpdate?: OrderItem;
  isUpdate?: boolean;
}

export function UpdateGiftWrapModal({ itemToUpdate, isOpen, onClose, isUpdate }: UpdateGiftWrapModalProps) {
  return (
    <GiftWrapModal
      itemToUpdate={itemToUpdate}
      isOpen={isOpen}
      hideCloseButton={false}
      onClose={onClose}
      isUpdate={isUpdate}
    />
  );
}

interface GiftWrapModalProps {
  index?: number;
  itemToUpdate?: OrderItem;
  selectedProduct?: DeepPartial<SourceProduct>;
  isOpen: boolean;
  onNevermind?: () => void;
  onAddGiftWrap?: (giftWrapModifier: ProductModifierOption) => void;
  onRemoveGiftWrap?: (orderItemIndex: number, itemToUpdate: ClientOrderItem) => void;
  onUpdateSelection?: (
    orderItemIndex: number,
    itemToUpdate: ClientOrderItem,
    giftWrapModifier: ProductModifierOption,
  ) => void;
  giftWrapOptions?: ProductModifierOption[];
  hideCloseButton?: boolean;
  onClose?: () => void;
  initialGiftWrapOption?: ProductModifierOption;
  isUpdate?: boolean;
  backClickHandler?: () => void;
  onAddToBag?: (selectedGiftWrapModifier: ProductModifierOption) => void;
}

const GiftWrapModal = ({
  index,
  itemToUpdate,
  selectedProduct,
  isOpen,
  onNevermind,
  onAddGiftWrap,
  onRemoveGiftWrap,
  onUpdateSelection,
  hideCloseButton = true,
  onClose,
  initialGiftWrapOption,
  isUpdate,
  backClickHandler,
  onAddToBag,
}: GiftWrapModalProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { order, orderLoading, isUpsertLoading, orderTimeSlot, upsertOrderItems } = useOrderContextNew();
  const [selectedGiftWrap, setSelectedGiftWrap] = useState<ProductModifierOption | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const type = router.query.type as string;
  const orderType = type?.toUpperCase() as SourceType;
  const storeSlug = router.query.slug as string;

  const { data: storeData, loading: storeLoading } = useGetStoreBySlug({ storeSlug: storeSlug });
  const { data: sourceProductsData, loading: sourceProductsLoading } = useGetSource({
    storeId: storeData?.storeId,
    type: orderType,
    timeSlot: orderTimeSlot,
  });

  let product: DeepPartial<SourceProduct> = selectedProduct;
  if (!product) {
    product = sourceProductsData?.public?.sourceForStore?.products?.find(
      (p) => p?.product?.productId === itemToUpdate?.product?.productId,
    );
  }

  const giftWrapModifier = product?.product?.modifiers?.find((m) =>
    m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftwrapping),
  );

  const handleUpsertGiftWrap = async (giftWrapOption: ProductModifierOption) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      if (isUpdate) {
        const updatedModifiers = itemToUpdate.modifiers.map((modifier) => {
          if (modifier.modifierId === giftWrapModifier?.modifierId) {
            return {
              ...modifier,
              options: [giftWrapOption],
            };
          }
          return modifier;
        });

        await upsertOrderItems({
          order: order,
          items: [
            ...(order?.items?.map((item) => {
              if (getUniqueItemId(item) === getUniqueItemId(itemToUpdate)) {
                return {
                  ...item,
                  modifiers: updatedModifiers,
                };
              }
              return item;
            }) || []),
          ],
        });
      } else {
        // add gift wrap and product to order
        await onAddToBag(giftWrapOption);
      }
      onClose?.();
    } catch (error) {
      console.error("Error updating gift wrap: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      variant="secondaryNoDivider"
      isOpen={isOpen}
      onClose={onClose}
      dontCloseOnOutsideClick
      hideCloseButton={hideCloseButton}
      className="!bg-white"
    >
      <div className="flex flex-col gap-y-8 my-auto sm:max-w-[466px] sm:min-h-[644px] mx-auto">
        <div className="flex flex-col items-center">
          <Text className="sm:hidden" variant="title2">
            {t("order:select_your_gift_wrap")}
          </Text>
          <Image className="sm:pb-5 pt-6" src={ImagesV2.sixPackWithGiftWrap} alt={ImagesV2.illustrationGiftingBow} />
          <div className="px-[15px] pt-5 items-center">
            <div className="flex flex-col pb-[30px] items-center gap-y-1 text-center">
              <Text className="hidden sm:inline-flex" variant="title1">
                {t("order:select_your_gift_wrap")}
              </Text>
              <Text className="sm:text-lg">{t("order:gift_wrap_comes_with_bow")}</Text>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3">
            {giftWrapModifier?.options?.map((giftWrapOption) => (
              <button
                key={giftWrapOption?.optionId}
                id={giftWrapOption?.optionId}
                onClick={isLoading ? undefined : () => setSelectedGiftWrap(giftWrapOption as ProductModifierOption)}
                className={classNames("relative flex items-center justify-center", {
                  "opacity-50": isLoading,
                })}
              >
                <Image src={ImagesV2.blackCircle} alt={ImagesV2.blackCircle} />
                <Text className="absolute text-center text-white text-wrap px-2 py-[12px]">
                  {giftWrapOption.name?.replace(" (synced)", "")}
                </Text>
                {giftWrapOption.optionId === selectedGiftWrap?.optionId && (
                  <Image
                    src={ImagesV2.checkCircleWhite}
                    alt={ImagesV2.checkCircleWhite}
                    className="absolute top-0 right-0"
                  />
                )}
              </button>
            ))}
          </div>
        </div>

        <div
          className={classNames("flex flex-col sm:flex-row w-full gap-3 items-center mt-12 sm:mt-auto", {
            "opacity-50": isLoading,
          })}
        >
          {onClose && (
            <Button id="nevermind" className="hidden sm:inline-flex" variant="secondary" block onClick={onClose}>
              {t("order:nevermind")}
            </Button>
          )}
          {!isUpdate && (
            <Button
              id="addGiftWrap"
              contentRight={
                !!selectedGiftWrap?.price && <Text variant="finePrint">+{formatMoney(selectedGiftWrap?.price)}</Text>
              }
              block
              onClick={() => handleUpsertGiftWrap(selectedGiftWrap)}
            >
              {t("order:add_gift_wrap")}
            </Button>
          )}
          {onClose && (
            <Button id="skip" className="sm:hidden" variant="secondary" block onClick={onClose}>
              {t("order:skip")}
            </Button>
          )}
          {isUpdate && (
            <Button id="updateSelection" block onClick={() => handleUpsertGiftWrap(selectedGiftWrap)}>
              {t("order:update_selection")}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
