import classNames from "classnames";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { HTMLProps, SVGProps } from "react";
import IconAngleDownSmallRegular from "../../atoms/Icons/AngleDownSmallRegular";
import Text from "../../atoms/Text/Text";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

interface OrderInfoHeaderItemProps extends HTMLProps<HTMLButtonElement> {
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  primaryText: string;
  secondaryText?: string;
}

export default function OrderInfoHeaderItem({
  Icon,
  primaryText,
  secondaryText,
  className,
  onClick
}: OrderInfoHeaderItemProps) {
  return (
    <button className={classNames("flex items-center gap-x-[5px] w-full", className)} onClick={onClick}>
      {!!Icon && <Icon className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6" />}
      <Text variant="body1" className="truncate">
        <Text as="span" variant="title3">
          {primaryText}
        </Text>
        {secondaryText && ` - ${secondaryText}`}
      </Text>
      <IconAngleDownSmallRegular className="flex-shrink-0 w-6 h-6 hidden sm:block" />
    </button>
  );
}
