import { ProductModifierSpecialSubtype, type SourceType } from "@/generated/requests/pos";
import { ImagesV2 } from "@/public/images/all";
import { formatMoney } from "@/static/lib/util";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import IconTrashRegular from "../../atoms/Icons/TrashRegular";
import Text from "../../atoms/Text/Text";
import type { ClientOrderItem } from "../../contexts/OrderContext/helpers";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { useGetSource, useGetStoreBySlug } from "../../operations/queries";
import QuantitySelectionButton from "../QuantitySelectionButton/QuantitySelectionButton";

type CartOrderItemProps = {
  item: any;
  updateQuantity?: (newQuantity: number) => void;
  editGiftWrapModifierForItem?: (productId: ClientOrderItem) => void;
  cartIndex?: number;
  reward?: any;
  hideQuantitySelector?: boolean;
  isVouchered?: boolean;
  hideEditGiftWrap?: boolean;
};

export default function CartOrderItem({
  item,
  updateQuantity,
  editGiftWrapModifierForItem,
  cartIndex,
  reward,
  hideQuantitySelector = false,
  isVouchered,
  hideEditGiftWrap,
}: CartOrderItemProps) {
  const { locale } = useRouter();
  const router = useRouter();
  const { orderTimeSlot, order, orderLoading, isUpsertLoading } = useOrderContextNew();
  const { t } = useTranslation();

  const type = router.query.type as string;
  const orderType = type?.toUpperCase() as SourceType;
  const storeSlug = router.query.slug as string;

  const { data: storeData, loading: storeLoading } = useGetStoreBySlug({ storeSlug: storeSlug });
  const { data: sourceProductsData, loading: sourceProductsLoading } = useGetSource({
    storeId: storeData?.storeId || order?.storeId,
    type: orderType || order?.source?.type,
    timeSlot: orderTimeSlot,
  });

  const product = sourceProductsData?.public?.sourceForStore?.products?.find(
    (p) => p?.product?.productId === item?.product?.productId,
  );

  const originalPriceDisplay = formatMoney(product?.price);
  const discountedPriceDisplay = formatMoney(product?.automaticDiscounts?.priceWithDiscounts);
  const editableLocation =
    typeof window !== "undefined" &&
    !window.location.href.includes("/checkout") &&
    product?.product?.modifiers?.flatMap((m) => m?.options).length > 1;

  const numberFormatter = new Intl.NumberFormat(locale, {});

  const itemModifiers = item?.modifiers?.map((itemModifier) => {
    const matchingProdModifier = product?.product?.modifiers?.find(
      (productModifier) => productModifier?.modifierId === itemModifier?.modifierId,
    );
    if (!matchingProdModifier) return itemModifier;

    const mergedOptions = itemModifier?.options.map((itemOption) => {
      const matchingOption2 = matchingProdModifier.options.find(
        (productModifierOption) => productModifierOption.optionId === itemOption.modifierOptionId,
      );
      return matchingOption2 ? { ...itemOption, ...matchingOption2 } : itemOption;
    });

    return {
      ...itemModifier,
      ...matchingProdModifier,
      options: mergedOptions,
    };
  });

  const productFlavorModifier = itemModifiers?.find((m) =>
    m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Cookie),
  );
  const itemFlavorModifier = itemModifiers?.find((m) =>
    m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Cookie),
  );

  const giftWrapModifierInItem = itemModifiers?.find((m) =>
    m?.specialSubtypes?.includes(ProductModifierSpecialSubtype.Giftwrapping),
  );

  const selectedGiftWrapOptionId = item?.modifiers?.find(
    (itemModifier) => itemModifier?.modifierId === giftWrapModifierInItem?.modifierId,
  )?.options?.[0]?.modifierOptionId;
  const selectedGiftWrapOption = giftWrapModifierInItem?.options?.find((o) => o?.optionId === selectedGiftWrapOptionId);

  const lineItemsTexts = itemFlavorModifier?.options?.map((option) => {
    return `${option?.quantity} x ${option?.name}${option?.price ? ` (+${formatMoney(option?.price)} ea.)` : ""}`;
  });

  return (
    <div className="w-full py-[15px] border-b border-solid border-grey-10 flex flex-col animate-fadeInSlow">
      <div className="flex items-start justify-between w-full">
        <div className="flex items-start">
          {editableLocation ? (
            <Link
              href={{
                pathname: `/order/${orderType}/${storeSlug}`,
                query: { editIndex: cartIndex },
              }}
              className="w-[90px] h-[90px] min-w-[90px] min-h-[90px] rounded-lg bg-[#FFB9CD] relative flex items-center justify-center"
            >
              <Image
                src={product?.product?.image}
                width={320}
                height={320}
                alt={t(product?.product?.name)}
                className="rounded-lg object-cover w-full h-full"
              />
            </Link>
          ) : (
            <div className="w-[90px] h-[90px] min-w-[90px] min-h-[90px] rounded-lg bg-[#FFB9CD] relative flex items-center justify-center">
              <Image
                src={product?.product?.image}
                width={320}
                height={320}
                alt={t(product?.product?.name)}
                className="rounded-lg"
              />
            </div>
          )}
          <div className="ml-[10px]">
            <Text variant="lineItem">{t(product?.product?.name)}</Text>
            <div className="mb-[10px]">
              <div className="flex gap-3">
                <Text
                  variant="finePrint"
                  className={classNames({
                    "text-grey-60 line-through decoration-grey-60":
                      !!reward || !!product?.automaticDiscounts?.priceWithDiscounts || isVouchered,
                  })}
                >
                  {originalPriceDisplay}
                </Text>
                {!!product?.automaticDiscounts?.priceWithDiscounts && !reward && !isVouchered && (
                  <Text
                    variant="finePrint"
                    className={classNames({
                      hidden: !!reward,
                    })}
                  >
                    {discountedPriceDisplay}
                  </Text>
                )}
                {isVouchered && <Text variant="finePrint">{t("order:free")}</Text>}
              </div>
              {reward && (
                <Text variant="caption" as="span" className="bg-primary rounded px-2 py-1 my-1 !font-bold">
                  {t("order:x_crumbs", { x: numberFormatter.format(reward.pointCostAtTimeOfOrder) })}
                </Text>
              )}
            </div>
            {lineItemsTexts?.map((lineItemText) => {
              return (
                <Text key={lineItemText} variant="finePrint" className="text-grey-40">
                  {lineItemText}
                </Text>
              );
            })}
          </div>
        </div>
        {!hideQuantitySelector && (
          <QuantitySelectionButton
            quantity={item?.quantity}
            onUpdateQuantity={(quantity) => {
              updateQuantity(quantity);
              return quantity;
            }}
            // We only allow incrementing quantity if this item is not a reward item
            onIncrement={reward || isVouchered ? null : () => updateQuantity(item?.quantity + 1)}
            onDecrement={() => updateQuantity(item?.quantity - 1)}
            iconForDecrement={item?.quantity <= 1 && <IconTrashRegular width={20} height={20} fillOpacity={"0.4"} />}
            className="w-[110px] max-w-[110px] h-[40px] max-h-[40px]"
            isLoading={isUpsertLoading || orderLoading}
          />
        )}
      </div>
      {selectedGiftWrapOption && (
        <div className="w-full flex justify-between border border-solid border-grey-10 rounded-lg gap-2.5 items-center mt-3">
          <div className="flex items-center">
            <Image src={ImagesV2.giftWrapBow} alt="Gift wrap bow" className="pl-2 size-12" />
            <div className="flex flex-col">
              <div className="flex gap-1 items-center">
                <Text variant="smallHeader">{t("order:gift_Wrap")}</Text>
                <Text variant="finePrint">+({formatMoney(selectedGiftWrapOption?.price)})</Text>
              </div>
              <Text variant="finePrint">
                {t("order:sticker")}: "{selectedGiftWrapOption?.name}"
              </Text>
            </div>
          </div>
          {!hideEditGiftWrap && (
            <div className="px-4 pb-4">
              <button onClick={() => editGiftWrapModifierForItem(item)}>
                <Text variant="finePrint" className="underline text-grey-60">
                  {t("order:edit")}
                </Text>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
