import { useEffect, useRef } from "react";

export const useResize = (callback: (ref: any) => void) => {
  const containerRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", callback);
    callback(containerRef);
    return () => window.removeEventListener("resize", callback);
  }, [callback]);

  return containerRef;
};
